import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { updateMaterial } from '../api/warehouse'; // Import the updateMaterial API function

interface EditMaterialFormProps {
    material: any; // Replace `any` with the correct type if you have one
    onCancel: () => void;
    onUpdate: () => void; // No need to pass the material object back, just a refresh call
}

const EditMaterialForm: React.FC<EditMaterialFormProps> = ({ material, onCancel, onUpdate }) => {
    const { t } = useTranslation();
    const [name, setName] = useState(material.name);
    const [pricePerKg, setPricePerKg] = useState(material.pricePerKg);
    const [quantityKg, setQuantityKg] = useState(material.totalQuantityKg);

    const handleUpdate = async () => {
        try {
            // Create an updated material object
            const updatedMaterial = {
                name,
                pricePerKg,
                totalQuantityKg: quantityKg,
            };

            // Call the update API
            await updateMaterial(material.id, updatedMaterial);

            // Refresh the materials list in parent component
            onUpdate();
        } catch (err) {
            console.error('Error updating material:', err);
        }
    };

    return (
        <Form>
            <h3>{t('storagePage.editMaterialTitle')}</h3>
            <Form.Group controlId="materialName">
                <Form.Label>{t('storagePage.materialName')}</Form.Label>
                <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="materialPrice">
                <Form.Label>{t('storagePage.materialPrice')}</Form.Label>
                <Form.Control
                    type="number"
                    value={pricePerKg}
                    onChange={(e) => setPricePerKg(parseFloat(e.target.value))}
                    min="0"
                    step="0.01"
                />
            </Form.Group>
            <Form.Group controlId="materialQuantity">
                <Form.Label>{t('storagePage.materialQuantity')}</Form.Label>
                <Form.Control
                    type="number"
                    value={quantityKg}
                    onChange={(e) => setQuantityKg(parseFloat(e.target.value))}
                    min="0"
                    step="0.01"
                />
            </Form.Group>

            <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                    <Button variant="primary" onClick={handleUpdate} className="me-2">
                        {t('storagePage.saveChangesButton')}
                    </Button>
                    <Button variant="secondary" onClick={onCancel}>
                        {t('common.cancelButton')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default EditMaterialForm;

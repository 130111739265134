import api from './index';

// Example for login
export const login = async (username: string, password: string) => {
    return await api.post('/api/auth/login', { username, password }, { withCredentials: true });
};

// Example for session validation
export const validateSession = async () => {
    return await api.post('/api/auth/validate-session', {}, {withCredentials: true});
};

// Example for logout
export const logout = async () => {
    return await api.post('/api/auth/logout', {}, {withCredentials: true});
};

export default api;

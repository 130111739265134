import api from './index';

// Fetch all users with their roles and permissions
export const fetchUsers = async () => {
    console.log("Fetching users with roles and permissions...");
    try {
        const response = await api.get('/api/permissions/users');
        console.log("Fetched users:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching users:", error);
        throw error;
    }
};

// Fetch all roles with their associated permissions and users
export const fetchRoles = async () => {
    console.log("Fetching roles with permissions and associated users...");
    try {
        const response = await api.get('/api/permissions/roles');
        console.log("Fetched roles:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching roles:", error);
        throw error;
    }
};

// Fetch all permissions
export const fetchPermissions = async () => {
    console.log("Fetching all permissions...");
    try {
        const response = await api.get('/api/permissions/permissions');
        console.log("Fetched permissions:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching permissions:", error);
        throw error;
    }
};

// Assign a permission to a role
export const assignPermissionToRole = async (roleId: number, permissionId: number) => {
    console.log(`Assigning permission ${permissionId} to role ${roleId}...`);
    try {
        const response = await api.post(`/api/permissions/roles/${roleId}/permissions/${permissionId}`);
        console.log("Permission assigned:", response.data);
    } catch (error) {
        console.error("Error assigning permission to role:", error);
        throw error;
    }
};

// Remove a permission from a role
export const removePermissionFromRole = async (roleId: number, permissionId: number) => {
    console.log(`Removing permission ${permissionId} from role ${roleId}...`);
    try {
        const response = await api.delete(`/api/permissions/roles/${roleId}/permissions/${permissionId}`);
        console.log("Permission removed from role:", response.data);
    } catch (error) {
        console.error("Error removing permission from role:", error);
        throw error;
    }
};

// Assign a permission to a user
export const assignPermissionToUser = async (userId: number, permissionId: number) => {
    console.log(`Assigning permission ${permissionId} to user ${userId}...`);
    try {
        const response = await api.post(`/api/permissions/users/${userId}/permissions/${permissionId}`);
        console.log("Permission assigned to user:", response.data);
    } catch (error) {
        console.error("Error assigning permission to user:", error);
        throw error;
    }
};

// Remove a permission from a user
export const removePermissionFromUser = async (userId: number, permissionId: number) => {
    console.log(`Removing permission ${permissionId} from user ${userId}...`);
    try {
        const response = await api.delete(`/api/permissions/users/${userId}/permissions/${permissionId}`);
        console.log("Permission removed from user:", response.data);
    } catch (error) {
        console.error("Error removing permission from user:", error);
        throw error;
    }
};

export default api;

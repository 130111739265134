import React, { useState, useEffect } from 'react';
import { getProducts, updateProduct, deleteProduct } from '../api/products';
import { Button, Table, Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AddProductForm from '../components/AddProductForm';
import { Material } from '../models/Material'; // Assuming you have a Material type

const ProductsPage: React.FC = () => {
    const { t } = useTranslation();
    const [products, setProducts] = useState<Material[]>([]);
    const [showProducts, setShowProducts] = useState<boolean>(false);
    const [editProduct, setEditProduct] = useState<Material | null>(null);
    const [showAddForm, setShowAddForm] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Fetch products that are marked as `isProduct`
    useEffect(() => {
        if (showProducts) {
            fetchProducts();
        }
    }, [showProducts]);

    const fetchProducts = async () => {
        try {
            const data = await getProducts();
            setProducts(data);
        } catch (err) {
            console.error('Error fetching products:', err);
            setError(t('productsPage.fetchError'));
        }
    };

    // Toggle product list visibility
    const toggleProductsVisibility = () => {
        setShowProducts(!showProducts);
    };

    // Toggle Add Product form visibility
    const toggleAddFormVisibility = () => {
        setShowAddForm(!showAddForm);
    };

    // Handle product update
    const handleEditProduct = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!editProduct) return;

        try {
            const updatedProduct: Partial<Material> = {
                name: editProduct.name,
                weightRequiredKg: editProduct.weightRequiredKg,
                sellingPrice: editProduct.sellingPrice,
            };

            await updateProduct(editProduct.id, updatedProduct);
            setEditProduct(null);
            fetchProducts();
        } catch (err) {
            console.error('Error updating product:', err);
            setError(t('productsPage.updateError'));
        }
    };

    // Handle product deletion
    const handleDeleteProduct = async (productId: number) => {
        if (window.confirm(t('productsPage.deleteConfirmation'))) {
            try {
                await deleteProduct(productId);
                fetchProducts();
            } catch (err) {
                console.error('Error deleting product:', err);
                setError(t('productsPage.deleteError'));
            }
        }
    };

    // Handle form changes for product editing
    const handleEditChange = (field: keyof Material, value: string | number) => {
        if (!editProduct) return;
        setEditProduct({ ...editProduct, [field]: value });
    };

    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <h1 className="me-auto">{t('productsPage.title')}</h1>
                <Button onClick={toggleProductsVisibility} className="me-2">
                    {showProducts ? t('common.cancelButton') : t('productsPage.showProducts')}
                </Button>
                <Button onClick={toggleAddFormVisibility} variant="success">
                    {showAddForm ? t('common.cancelButton') : t('productsPage.addNewProduct')}
                </Button>
            </div>

            {/* Display error alert if there's an error */}
            {error && <Alert variant="danger">{error}</Alert>}

            {/* Conditionally render products table */}
            {showProducts && (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>{t('productsPage.productName')}</th>
                        <th>{t('productsPage.weightRequired')}</th>
                        <th>{t('productsPage.sellingPrice')}</th>
                        <th>{t('productsPage.actions')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {products.map((product) => (
                        <tr key={product.id}>
                            <td>{product.name}</td>
                            <td>{product.weightRequiredKg}</td>
                            <td>{product.sellingPrice}</td>
                            <td>
                                <Button variant="warning" onClick={() => setEditProduct(product)}>
                                    {t('common.editButton')}
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => handleDeleteProduct(product.id)}
                                    className="ms-2"
                                >
                                    {t('common.deleteButton')}
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}

            {/* Conditionally render Add Product form */}
            {showAddForm && (
                <AddProductForm
                    onAdd={() => {
                        fetchProducts();
                        setShowAddForm(false);
                    }}
                    onCancel={toggleAddFormVisibility}
                />
            )}

            {/* Conditionally render Edit Product form */}
            {editProduct && (
                <Form onSubmit={handleEditProduct} className="mt-3">
                    <h3>{t('productsPage.editProduct')}</h3>
                    <Form.Group controlId="productName">
                        <Form.Label>{t('productsPage.productName')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={editProduct.name}
                            onChange={(e) => handleEditChange('name', e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="productWeightRequired" className="mt-2">
                        <Form.Label>{t('productsPage.weightRequired')}</Form.Label>
                        <Form.Control
                            type="number"
                            value={editProduct.weightRequiredKg}
                            onChange={(e) => handleEditChange('weightRequiredKg', parseFloat(e.target.value))}
                            min="0"
                            step="0.01"
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="productSellingPrice" className="mt-2">
                        <Form.Label>{t('productsPage.sellingPrice')}</Form.Label>
                        <Form.Control
                            type="number"
                            value={editProduct.sellingPrice}
                            onChange={(e) => handleEditChange('sellingPrice', parseFloat(e.target.value))}
                            min="0"
                            step="0.01"
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-3">
                        {t('common.saveButton')}
                    </Button>
                    <Button variant="secondary" onClick={() => setEditProduct(null)} className="mt-3 ms-2">
                        {t('common.cancelButton')}
                    </Button>
                </Form>
            )}
        </div>
    );
};

export default ProductsPage;

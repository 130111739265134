import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface PermissionTableProps {
    entities: { id: number; name?: string; username?: string; role?: string; permissions: { id: number; granted: boolean }[] }[];
    permissions: { id: number; name: string }[];
    onTogglePermission: (entityId: number, permissionId: number, granted: boolean) => void;
    entityType: 'Role' | 'User';
}

const PermissionTable: React.FC<PermissionTableProps> = ({ entities, permissions, onTogglePermission, entityType }) => {
    const { t } = useTranslation();

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>{entityType === 'Role' ? t('permissionTable.entity') : t('permissionTable.username')}</th>
                {entityType === 'User' && <th>{t('permissionTable.role')}</th>}
                {permissions.map(permission => (
                    <th key={permission.id}>{permission.name}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {entities.map(entity => (
                <tr key={entity.id}>
                    <td>{entityType === 'Role' ? entity.name : entity.username}</td>
                    {entityType === 'User' && <td>{entity.role}</td>}
                    {permissions.map(permission => {
                        const isGranted = entity.permissions.some(ep => ep.id === permission.id && ep.granted);
                        return (
                            <td key={permission.id} className="text-center">
                                <Button
                                    variant={isGranted ? 'success' : 'danger'}
                                    onClick={() => onTogglePermission(entity.id, permission.id, isGranted)}
                                >
                                    {isGranted ? t('permissionTable.granted') : t('permissionTable.revoked')}
                                </Button>
                            </td>
                        );
                    })}
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

export default PermissionTable;

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import WarehousePage from './pages/WarehousePage';
import ProductsPage from './pages/ProductsPage';
import OptimizationPage from './pages/OptimizationPage';
import VersionInfoPage from './pages/VersionInfoPage';
import FutureVersionPage from './pages/FutureVersionPage';
import IdeasPage from './pages/IdeasPage';
import ManagerPage from './pages/ManagerPage';
import ProtectedRoute from './components/ProtectedRoute';

interface RoutesProps {
    userRole: string | null;
    userPermissions: string[];
    onLoginSuccess: (role: string, permissions: string[], user: string) => void; // Update to include `user`
    loading: boolean;
}

const AppRoutes: React.FC<RoutesProps> = ({ userRole, userPermissions, onLoginSuccess, loading }) => (
    <Routes>
        {/* Public Routes */}
        <Route path="/" element={<MainPage userRole={userRole} />} />

        <Route path="/login" element={<LoginPage onLoginSuccess={onLoginSuccess} />} />

        {/* Protected Routes based on permissions */}
        <Route
            path="/storage"
            element={
                <ProtectedRoute requiredPermission="view_warehouse" userPermissions={userPermissions} loading={loading}>
                    <WarehousePage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/products"
            element={
                <ProtectedRoute requiredPermission="view_products" userPermissions={userPermissions} loading={loading}>
                    <ProductsPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/optimize"
            element={
                <ProtectedRoute requiredPermission="view_optimization" userPermissions={userPermissions} loading={loading}>
                    <OptimizationPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/current-version"
            element={
                <ProtectedRoute requiredPermission="view_version_info" userPermissions={userPermissions} loading={loading}>
                    <VersionInfoPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/future-version"
            element={
                <ProtectedRoute requiredPermission="view_future_version" userPermissions={userPermissions} loading={loading}>
                    <FutureVersionPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/future-ideas"
            element={
                <ProtectedRoute requiredPermission="view_ideas" userPermissions={userPermissions} loading={loading}>
                    <IdeasPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/manage"
            element={
                <ProtectedRoute requiredPermission="view_manager" userPermissions={userPermissions} loading={loading}>
                    <ManagerPage />
                </ProtectedRoute>
            }
        />

        {/* Redirect unmatched routes to the main page */}
        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
);

export default AppRoutes;

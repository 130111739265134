import React, { useState } from 'react';
import { Container, Navbar, Nav, Button, NavDropdown, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';

interface HeaderProps {
    userRole: string | null;
    userPermissions: string[];
    username: string | null;
    onLogout: () => void;
    loading: boolean;
}

const Header: React.FC<HeaderProps> = ({ userRole, userPermissions, username, onLogout, loading }) => {
    const { t, i18n } = useTranslation();
    const [showAccessModal, setShowAccessModal] = useState(false);

    const handleRestrictedAccess = () => {
        setShowAccessModal(true);
    };

    const closeModal = () => {
        setShowAccessModal(false);
    };

    const toggleLanguage = () => {
        const newLang = i18n.language === 'sk' ? 'en' : 'sk';
        i18n.changeLanguage(newLang);
    };

    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg" sticky="top" className="shadow-sm">
                <Container>
                    <LinkContainer to="/">
                        <Navbar.Brand className="fw-bold">{t('branding.brand')}</Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {userRole && (
                            <Nav className="me-auto">
                                {userPermissions.includes("view_warehouse") ? (
                                    <LinkContainer to="/storage">
                                        <Nav.Link>{t('header.warehouse')}</Nav.Link>
                                    </LinkContainer>
                                ) : (
                                    <Nav.Link onClick={handleRestrictedAccess}>{t('header.warehouse')}</Nav.Link>
                                )}
                                {userPermissions.includes("view_products") ? (
                                    <LinkContainer to="/products">
                                        <Nav.Link>{t('header.products')}</Nav.Link>
                                    </LinkContainer>
                                ) : (
                                    <Nav.Link onClick={handleRestrictedAccess}>{t('header.products')}</Nav.Link>
                                )}
                                {userPermissions.includes("view_optimization") ? (
                                    <LinkContainer to="/optimize">
                                        <Nav.Link>{t('header.optimization')}</Nav.Link>
                                    </LinkContainer>
                                ) : (
                                    <Nav.Link onClick={handleRestrictedAccess}>{t('header.optimization')}</Nav.Link>
                                )}
                                {userPermissions.includes("view_manager") ? (
                                    <LinkContainer to="/manage">
                                        <Nav.Link>{t('header.manage')}</Nav.Link>
                                    </LinkContainer>
                                ) : (
                                    <Nav.Link onClick={handleRestrictedAccess}>{t('header.manage')}</Nav.Link>
                                )}
                            </Nav>
                        )}

                        <Nav className="ms-auto d-flex align-items-center">
                            {userRole && (
                                <NavDropdown title="Version Info" id="version-dropdown" className="me-3">
                                    {userPermissions.includes("view_version_info") ? (
                                        <LinkContainer to="/current-version">
                                            <NavDropdown.Item>{t('header.currentVersion')}</NavDropdown.Item>
                                        </LinkContainer>
                                    ) : (
                                        <NavDropdown.Item onClick={handleRestrictedAccess}>
                                            {t('header.currentVersion')}
                                        </NavDropdown.Item>
                                    )}
                                    {userPermissions.includes("view_future_version") ? (
                                        <LinkContainer to="/future-version">
                                            <NavDropdown.Item>{t('header.upcomingVersion')}</NavDropdown.Item>
                                        </LinkContainer>
                                    ) : (
                                        <NavDropdown.Item onClick={handleRestrictedAccess}>
                                            {t('header.upcomingVersion')}
                                        </NavDropdown.Item>
                                    )}
                                    {userPermissions.includes("view_ideas") ? (
                                        <LinkContainer to="/future-ideas">
                                            <NavDropdown.Item>{t('header.futureIdeas')}</NavDropdown.Item>
                                        </LinkContainer>
                                    ) : (
                                        <NavDropdown.Item onClick={handleRestrictedAccess}>
                                            {t('header.futureIdeas')}
                                        </NavDropdown.Item>
                                    )}
                                </NavDropdown>
                            )}

                            <Button
                                variant="outline-light"
                                onClick={toggleLanguage}
                                className="me-3"
                                size="sm"
                            >
                                {i18n.language === 'en' ? 'SK' : 'EN'}
                            </Button>

                            {username && (
                                <div className="d-flex align-items-center text-light me-3">
                                    <FaUserCircle size={20} className="me-2" />
                                    <span>{username}</span>
                                </div>
                            )}

                            {userRole ? (
                                <Button variant="outline-light" onClick={onLogout} size="sm">
                                    {t('header.logout')}
                                </Button>
                            ) : (
                                <LinkContainer to="/login">
                                    <Button variant="outline-light" size="sm">
                                        {t('login.loginButton')}
                                    </Button>
                                </LinkContainer>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* Modal for insufficient permissions */}
            <Modal show={showAccessModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('protectedRoute.accessDeniedTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('protectedRoute.accessDeniedMessage')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        {t('header.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Header;

import React, { useState } from 'react';
import { Card, Button, Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface LoginFormProps {
    onSubmit: (username: string, password: string) => void;
    error: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, error }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { t } = useTranslation(); // Use the `useTranslation` hook for translations

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(username, password);
    };

    return (
        <Card className="p-4 shadow-lg">
            <Card.Body>
                <Card.Title className="text-center mb-4" style={{ fontSize: '1.75rem', fontWeight: 'bold' }}>
                    {t('login.loginTitle')}
                </Card.Title>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formUsername" className="mb-3">
                        <Form.Label>{t('login.usernameLabel')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="py-3"
                            size="lg"
                            placeholder={t('login.usernameLabel')}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formPassword" className="mb-3">
                        <Form.Label>{t('login.passwordLabel')}</Form.Label>
                        <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="py-3"
                            size="lg"
                            placeholder={t('login.passwordLabel')}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100 mt-3" size="lg">
                        {t('login.loginButton')}
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default LoginForm;

import React from 'react';
import { Container } from 'react-bootstrap';

const FutureVersionPage: React.FC = () => {
    return (
        <Container>
            <h1>Upcoming Version</h1>
            <p>This page contains information about what is coming in the next version.</p>
            <ul>
                <li>Feature 1: Improved optimization algorithms.</li>
                <li>Feature 2: Enhanced user management.</li>
                <li>Feature 3: Bug fixes and performance improvements.</li>
            </ul>
        </Container>
    );
};

export default FutureVersionPage;

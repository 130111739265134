import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { addProduct } from '../api/products';
import api from '../api/index';
import { useTranslation } from 'react-i18next';
import { MaterialComposition } from '../models/MaterialComposition';
import { Element } from '../models/Element';

interface AddProductFormProps {
    onAdd: () => void;
    onCancel: () => void;
}

const AddProductForm: React.FC<AddProductFormProps> = ({ onAdd, onCancel }) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [weight, setWeight] = useState('');
    const [price, setPrice] = useState('');
    const [isProduct] = useState(true); // Default to `true` as it's a product
    const [compositions, setCompositions] = useState<Partial<MaterialComposition>[]>([
        { elementId: 0, quantity: 0, tolerance: 0 },
    ]);
    const [error, setError] = useState<string>('');
    const [elements, setElements] = useState<Element[]>([]);

    useEffect(() => {
        const fetchElements = async () => {
            try {
                const response = await api.get('/api/elements');
                setElements(response.data);
            } catch (err) {
                console.error('Error fetching elements:', err);
                setError(t('productsPage.errorFetchingElements'));
            }
        };
        fetchElements();
    }, [t]);

    // Ensure the sum of quantities equals 100%
    const validateComposition = () => {
        const totalQuantity = compositions.reduce((sum, comp) => sum + (comp.quantity || 0), 0);
        if (totalQuantity !== 100) {
            setError(t('productsPage.invalidCompositionSum'));
            return false;
        }
        return true;
    };

    const handleAddComposition = () => {
        setCompositions([...compositions, { elementId: elements[0]?.id || 0, quantity: 0, tolerance: 0 }]);
    };

    const handleCompositionChange = (index: number, field: keyof MaterialComposition, value: string) => {
        const newCompositions = [...compositions];
        newCompositions[index] = { ...newCompositions[index], [field]: parseFloat(value) || 0 };
        setCompositions(newCompositions);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');

        // Validate composition
        if (!validateComposition()) return;

        try {
            const product = {
                name,
                weightRequiredKg: parseFloat(weight),
                sellingPrice: parseFloat(price),
                isRaw: false, // Set isRaw to `false` by default
                isProduct: true, // Always `true` for products
                compositions: compositions.map((comp) => ({
                    elementId: comp.elementId!,
                    quantity: comp.quantity!,
                    tolerance: comp.tolerance!,
                })),
            };

            if (!product.name || isNaN(product.weightRequiredKg) || isNaN(product.sellingPrice)) {
                setError(t('productsPage.invalidDataFormat'));
                return;
            }

            await addProduct(product);
            alert(t('productsPage.productAddedSuccess'));
            setName('');
            setWeight('');
            setPrice('');
            setCompositions([{ elementId: elements[0]?.id || 0, quantity: 0, tolerance: 0 }]);
            onAdd();
        } catch (err) {
            console.error('Error adding product:', err);
            setError(t('productsPage.invalidDataFormat'));
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h3>{t('productsPage.addNewProduct')}</h3>
            {error && <Alert variant="danger">{error}</Alert>}

            {/* Product Name */}
            <Form.Group controlId="productName">
                <Form.Label>{t('productsPage.productName')}</Form.Label>
                <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </Form.Group>

            {/* Product Weight */}
            <Form.Group controlId="productWeight" className="mt-3">
                <Form.Label>{t('productsPage.weightRequired')}</Form.Label>
                <Form.Control
                    type="number"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    required
                    min="0"
                    step="0.01"
                />
            </Form.Group>

            {/* Product Price */}
            <Form.Group controlId="productPrice" className="mt-3">
                <Form.Label>{t('productsPage.sellingPrice')}</Form.Label>
                <Form.Control
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                    min="0"
                    step="0.01"
                />
            </Form.Group>

            {/* Is Product Checkbox (Disabled and checked by default) */}
            <Form.Group controlId="productIsProduct" className="mt-3">
                <Form.Check
                    type="checkbox"
                    label={t('productsPage.isProduct')}
                    checked={isProduct}
                    disabled // Prevent user from unchecking
                />
            </Form.Group>

            {/* Compositions Section */}
            <h4 className="mt-4">{t('productsPage.compositions')}</h4>
            <Row className="g-3">
                {compositions.map((comp, index) => (
                    <Col xs={12} md={6} lg={4} key={index} className="composition-group">
                        {/* Select Element */}
                        <Form.Group controlId={`compositionElementId-${index}`}>
                            <Form.Label>{t('productsPage.element')}</Form.Label>
                            <Form.Control
                                as="select"
                                value={comp.elementId}
                                onChange={(e) => handleCompositionChange(index, 'elementId', e.target.value)}
                                required
                            >
                                {elements.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.nameFull} ({el.nameShort})
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        {/* Composition Quantity (Percentage) */}
                        <Form.Group controlId={`compositionQuantity-${index}`} className="mt-2">
                            <Form.Label>{t('productsPage.compositionQuantity')} (%)</Form.Label>
                            <Form.Control
                                type="number"
                                value={comp.quantity}
                                onChange={(e) => handleCompositionChange(index, 'quantity', e.target.value)}
                                required
                                min="0"
                                max="100"
                                step="0.01"
                            />
                        </Form.Group>

                        {/* Composition Tolerance */}
                        <Form.Group controlId={`compositionTolerance-${index}`} className="mt-2">
                            <Form.Label>{t('productsPage.compositionTolerance')}</Form.Label>
                            <Form.Control
                                type="number"
                                value={comp.tolerance}
                                onChange={(e) => handleCompositionChange(index, 'tolerance', e.target.value)}
                                required
                                min="0"
                                step="0.01"
                            />
                        </Form.Group>
                    </Col>
                ))}
            </Row>

            {/* Form Buttons */}
            <div className="d-flex justify-content-end mt-4">
                <Button variant="secondary" onClick={handleAddComposition} className="me-2">
                    {t('productsPage.addComposition')}
                </Button>
                <Button variant="primary" type="submit">
                    {t('productsPage.addProduct')}
                </Button>
                <Button variant="secondary" onClick={onCancel} className="ms-2">
                    {t('common.cancelButton')}
                </Button>
            </div>
        </Form>
    );
};

export default AddProductForm;

import React, { useEffect, useState } from 'react';
import { getProducts } from '../api/products';
import { getMaterials } from '../api/warehouse';
import { optimizeProduct } from '../api/optimize';
import { Container, Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Material } from '../models/Material';
import {  MaterialComposition } from '../models/MaterialComposition';

// Define the payload type for optimization
interface OptimizationPayload {
    product_id: string;
    warehouse_materials: Material[];
}

const OptimizationPage: React.FC = () => {
    const [products, setProducts] = useState<Material[]>([]);
    const [warehouseMaterials, setWarehouseMaterials] = useState<Material[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<string>('');
    const [optimizationResult, setOptimizationResult] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        fetchProducts();
        fetchWarehouseMaterials();

        const handleLanguageChange = () => {
            if (error) {
                setError(t('optimizationPage.errorFetchingProducts'));
            }
        };

        i18n.on('languageChanged', handleLanguageChange);

        // Cleanup listener on unmount
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n, t, error]);

    const fetchProducts = async () => {
        try {
            const data = await getProducts();
            setProducts(data);
        } catch (err) {
            console.error('Error fetching products:', err);
            setError(t('optimizationPage.errorFetchingProducts'));
        }
    };

    const fetchWarehouseMaterials = async () => {
        try {
            const materials = await getMaterials();
            setWarehouseMaterials(materials);
        } catch (err) {
            console.error('Error fetching warehouse materials:', err);
            setError(t('optimizationPage.errorFetchingWarehouseMaterials'));
        }
    };

    const handleOptimize = async () => {
        if (!selectedProduct) return;

        setLoading(true);
        setError('');
        setOptimizationResult(null);

        try {
            const payload: OptimizationPayload = {
                product_id: selectedProduct,
                warehouse_materials: warehouseMaterials,
            };

            const result = await optimizeProduct(payload);
            setOptimizationResult(result);
        } catch (err) {
            console.error('Error optimizing product:', err);
            setError(t('optimizationPage.errorOptimizing'));
        } finally {
            setLoading(false);
        }
    };

    const formatComposition = (compositions: MaterialComposition[]) => {
        if (!compositions || compositions.length === 0) {
            console.log('No compositions found:', compositions);
            return t('storagePage.noComposition');
        }

        console.log('Compositions:', compositions);

        return compositions
            .map((comp) => {
                console.log('Composition item:', comp);
                if (comp.element && comp.quantity) {
                    console.log('Valid element and quantity:', comp.element.nameShort, comp.quantity);
                    return `${comp.element.nameShort}${comp.quantity}`;
                }
                console.warn('Invalid composition:', comp);
                return '';
            })
            .filter(Boolean)
            .join('');
    };

    // Dummy handler for "Use this optimization" button
    const handleUseOptimization = () => {
        console.log('Using optimization:', optimizationResult);
        alert('Optimization applied! (functionality to be implemented)');
    };

    return (
        <Container fluid className="mt-4">
            <Row className="mb-4 text-center">
                <Col>
                    <h1>{t('optimizationPage.title')}</h1>
                </Col>
            </Row>

            {error && (
                <Row className="mb-4">
                    <Col>
                        <Alert variant="danger">{error}</Alert>
                    </Col>
                </Row>
            )}

            <Row className="justify-content-center mb-4">
                <Col xs={12} md={6} lg={4}>
                    <Form>
                        <Form.Group controlId="productSelect" className="mb-3">
                            <Form.Label>{t('optimizationPage.selectProductLabel')}</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedProduct}
                                onChange={(e) => setSelectedProduct(e.target.value)}
                                disabled={loading}
                            >
                                <option value="">{t('optimizationPage.selectProductPlaceholder')}</option>
                                {products.map((product) => (
                                    <option key={product.id} value={product.id.toString()}>
                                        {product.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Button
                            variant="primary"
                            onClick={handleOptimize}
                            disabled={!selectedProduct || loading}
                            className="w-100"
                        >
                            {loading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    {t('optimizationPage.optimizing')}
                                </>
                            ) : (
                                t('optimizationPage.optimizeButton')
                            )}
                        </Button>
                    </Form>
                </Col>
            </Row>

            {optimizationResult && optimizationResult.material_usage && (
                <Row className="justify-content-center">
                    <Col xs={12} md={8} lg={6}>
                        <h3 className="text-center">{t('optimizationPage.optimizationResults.title')}</h3>
                        <p className="text-center">
                            <strong>{t('optimizationPage.optimizationResults.totalCost')}:</strong> ${optimizationResult.total_cost}
                        </p>
                        <h5>{t('optimizationPage.optimizationResults.materialUsageTitle')}:</h5>
                        <ul className="list-group">
                            {Object.entries(optimizationResult.material_usage).map(([materialId, amount]) => {
                                const material = warehouseMaterials.find(mat => mat.id.toString() === materialId);
                                return (
                                    <li key={String(materialId)} className="list-group-item">
                                        {material
                                            ? `${material.name} (${formatComposition(material.compositions || [])}): ${amount} kg`
                                            : `${materialId}: ${amount} kg (Unknown Material)`}
                                    </li>
                                );
                            })}
                        </ul>

                        {/* Dummy "Use this optimization" button */}
                        <div className="text-center mt-4">
                            <Button variant="success" onClick={handleUseOptimization}>
                                {t('optimizationPage.useOptimization')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default OptimizationPage;

import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface ProtectedRouteProps {
    requiredPermission: string;
    userPermissions: string[];
    children: React.ReactNode;
    loading: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
                                                           requiredPermission,
                                                           userPermissions,
                                                           children,
                                                           loading,
                                                       }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const hasAccess = userPermissions.includes(requiredPermission);

    // Show modal only once when access is denied
    useEffect(() => {
        if (!loading && !hasAccess && !showModal) {
            setShowModal(true);
        }
    }, [loading, hasAccess, showModal]);

    const handleClose = () => {
        setShowModal(false);
        setRedirect(true);
    };

    if (loading) return null;

    if (redirect) {
        return <Navigate to="/" />; // Redirect to a safe route
    }

    return (
        <>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('protectedRoute.accessDeniedTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('protectedRoute.accessDeniedMessage')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('header.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {hasAccess ? children : null}
        </>
    );
};

export default ProtectedRoute;

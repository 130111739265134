import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './components/Header';
import AppRoutes from './routes';
import { validateSession, logout } from './api/auth';

const App: React.FC = () => {
    const [userRole, setUserRole] = useState<string | null>(null);
    const [userPermissions, setUserPermissions] = useState<string[]>([]);
    const [username, setUsername] = useState<string | null>(null); // New state for username
    const [loading, setLoading] = useState<boolean>(true);  // Track loading state
    const navigate = useNavigate();

    const handleValidateSession = async () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            setUserRole(null);
            setUserPermissions([]);
            setUsername(null);
            setLoading(false);
            return;
        }

        try {
            const response = await validateSession();
            if (!response || !response.data.role) throw new Error('Invalid session');
            setUserRole(response.data.role);
            setUserPermissions(response.data.permissions || []);
            setUsername(response.data.username); // Set username from session validation
        } catch (error) {
            console.error('Session validation failed:', error);
            localStorage.removeItem('authToken');
            setUserRole(null);
            setUserPermissions([]);
            setUsername(null);
        } finally {
            setLoading(false);  // End loading regardless of success or failure
        }
    };

    useEffect(() => {
        handleValidateSession();
    }, []);

    const handleLogout = async () => {
        console.log("Logout button clicked");  // Debug log
        try {
            await logout();
            localStorage.removeItem('authToken');
            setUserRole(null);
            setUserPermissions([]);
            setUsername(null);
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const handleLoginSuccess = (role: string, permissions: string[], user: string) => {
        setUserRole(role);
        setUserPermissions(permissions);
        setUsername(user); // Set username immediately on login
        setLoading(false);
        navigate('/');
    };

    return (
        <>
            <Header userRole={userRole} userPermissions={userPermissions} username={username} onLogout={handleLogout} loading={loading} />
            <Container className="mt-4">
                <AppRoutes
                    userRole={userRole}
                    userPermissions={userPermissions}
                    onLoginSuccess={handleLoginSuccess}
                    loading={loading}
                />
            </Container>
        </>
    );
};

export default App;

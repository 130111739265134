import React, { useState } from 'react';
import { Container, Row, Col, Card, ListGroup, Badge, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VersionInfoPage: React.FC = () => {
    const { t } = useTranslation(); // Initialize translation hook
    const [showOptimization, setShowOptimization] = useState(false);
    const [showProducts, setShowProducts] = useState(false);
    const [showWarehouse, setShowWarehouse] = useState(false);

    // Toggle functions for each section
    const toggleOptimization = () => setShowOptimization(!showOptimization);
    const toggleProducts = () => setShowProducts(!showProducts);
    const toggleWarehouse = () => setShowWarehouse(!showWarehouse);

    return (
        <Container className="mt-4">
            <h1 className="text-center mb-4">{t('versionInfoPage.title')}</h1>

            <h2 className="mb-4 text-primary">{t('versionInfoPage.version')}</h2>
            <p className="mb-4"><strong>{t('versionInfoPage.releaseDate')}</strong></p>

            {/* Optimization Page Section */}
            <Row className="mb-5">
                <Col>
                    <Card className="shadow-lg">
                        <Card.Header className="bg-primary text-white">
                            <Button variant="link" onClick={toggleOptimization} className="text-white">
                                <h3>{t('versionInfoPage.optimizationPage.title')}</h3>
                            </Button>
                        </Card.Header>
                        {showOptimization && (
                            <Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Badge bg="info" className="me-2">{t('versionInfoPage.common.fetch')}</Badge>
                                        {t('versionInfoPage.optimizationPage.fetchProducts')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="success" className="me-2">{t('versionInfoPage.common.select')}</Badge>
                                        {t('versionInfoPage.optimizationPage.selectProduct')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="success" className="me-2">{t('versionInfoPage.common.algorithm')}</Badge>
                                        {t('versionInfoPage.optimizationPage.algorithm')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="success" className="me-2">{t('versionInfoPage.common.results')}</Badge>
                                        <ul>
                                            <li>{t('versionInfoPage.optimizationPage.results.totalCost')}</li>
                                            <li>{t('versionInfoPage.optimizationPage.results.materialUsage')}</li>
                                        </ul>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="warning" className="me-2">{t('versionInfoPage.common.pending')}</Badge>
                                        {t('versionInfoPage.optimizationPage.useOptimization')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="danger" className="me-2">{t('versionInfoPage.common.errorHandling')}</Badge>
                                        {t('versionInfoPage.optimizationPage.errorHandling')}
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        )}
                    </Card>
                </Col>
            </Row>

            {/* Products Page Section */}
            <Row className="mb-5">
                <Col>
                    <Card className="shadow-lg">
                        <Card.Header className="bg-success text-white">
                            <Button variant="link" onClick={toggleProducts} className="text-white">
                                <h3>{t('versionInfoPage.productsPage.title')}</h3>
                            </Button>
                        </Card.Header>
                        {showProducts && (
                            <Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Badge bg="info" className="me-2">{t('versionInfoPage.common.fetch')}</Badge>
                                        {t('versionInfoPage.productsPage.fetchProducts')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="success" className="me-2">{t('versionInfoPage.common.add')}</Badge>
                                        {t('versionInfoPage.productsPage.addProduct')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="warning" className="me-2">{t('versionInfoPage.common.edit')}</Badge>
                                        {t('versionInfoPage.productsPage.editProduct')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="danger" className="me-2">{t('versionInfoPage.common.delete')}</Badge>
                                        {t('versionInfoPage.productsPage.deleteProduct')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="info" className="me-2">{t('versionInfoPage.common.toggle')}</Badge>
                                        {t('versionInfoPage.productsPage.toggleProducts')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="danger" className="me-2">{t('versionInfoPage.common.errorHandling')}</Badge>
                                        {t('versionInfoPage.productsPage.errorHandling')}
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        )}
                    </Card>
                </Col>
            </Row>

            {/* Warehouse Page Section */}
            <Row className="mb-5">
                <Col>
                    <Card className="shadow-lg">
                        <Card.Header className="bg-warning text-dark">
                            <Button variant="link" onClick={toggleWarehouse} className="text-dark">
                                <h3>{t('versionInfoPage.warehousePage.title')}</h3>
                            </Button>
                        </Card.Header>
                        {showWarehouse && (
                            <Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Badge bg="info" className="me-2">{t('versionInfoPage.common.fetch')}</Badge>
                                        {t('versionInfoPage.warehousePage.fetchMaterials')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="success" className="me-2">{t('versionInfoPage.common.add')}</Badge>
                                        {t('versionInfoPage.warehousePage.addMaterial')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="warning" className="me-2">{t('versionInfoPage.common.edit')}</Badge>
                                        {t('versionInfoPage.warehousePage.editMaterial')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="danger" className="me-2">{t('versionInfoPage.common.delete')}</Badge>
                                        {t('versionInfoPage.warehousePage.deleteMaterial')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="info" className="me-2">{t('versionInfoPage.common.toggle')}</Badge>
                                        {t('versionInfoPage.warehousePage.toggleMaterials')}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Badge bg="danger" className="me-2">{t('versionInfoPage.common.errorHandling')}</Badge>
                                        {t('versionInfoPage.warehousePage.errorHandling')}
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        )}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default VersionInfoPage;

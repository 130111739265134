import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { addMaterial } from '../api/warehouse';
import api from '../api/index';
import { useTranslation } from 'react-i18next';

const AddMaterialForm: React.FC = () => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [pricePerKg, setPricePerKg] = useState('');
    const [totalQuantityKg, setTotalQuantityKg] = useState('');
    const [weightRequiredKg, setWeightRequiredKg] = useState(''); // Ensure this is editable
    const [sellingPrice, setSellingPrice] = useState('');
    const [compositions, setCompositions] = useState([{ elementId: '', quantity: '', tolerance: '' }]);
    const [error, setError] = useState('');
    const [elements, setElements] = useState<any[]>([]);

    useEffect(() => {
        const fetchElements = async () => {
            try {
                const response = await api.get('/api/elements');
                setElements(response.data);
            } catch (err) {
                console.error('Error fetching elements:', err);
            }
        };
        fetchElements();
    }, []);

    const handleAddComposition = () => {
        setCompositions([...compositions, { elementId: '', quantity: '', tolerance: '' }]);
    };

    const handleCompositionChange = (index: number, field: string, value: string) => {
        const newCompositions = [...compositions];
        newCompositions[index] = { ...newCompositions[index], [field]: value };
        setCompositions(newCompositions);
    };

    // Ensure compositions total to 100%
    const totalCompositionPercentage = compositions.reduce((acc, comp) => acc + parseFloat(comp.quantity || '0'), 0);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Validate composition percentage
        if (totalCompositionPercentage !== 100) {
            setError(t('productsPage.invalidCompositionPercentage'));
            return;
        }

        setError('');
        try {
            const material = {
                name,
                pricePerKg: parseFloat(pricePerKg),
                totalQuantityKg: parseFloat(totalQuantityKg),
                weightRequiredKg: parseFloat(weightRequiredKg),
                sellingPrice: parseFloat(sellingPrice),
                isRaw: true, // Ensure `isRaw` is always true
                isProduct: false, // Default to false, as `isProduct` is not displayed
                compositions: compositions.map((comp) => ({
                    elementId: parseInt(comp.elementId?.toString() || '0'),
                    quantity: parseFloat(comp.quantity?.toString() || '0'),
                    tolerance: parseFloat(comp.tolerance?.toString() || '0'),
                })),
            };

            console.log('Material object being sent:', material);

            await addMaterial(material);
            alert(t('storagePage.materialAddedSuccess'));
            setName('');
            setPricePerKg('');
            setTotalQuantityKg('');
            setWeightRequiredKg(''); // Reset to default after submission
            setSellingPrice('');
            setCompositions([{ elementId: '', quantity: '', tolerance: '' }]);
        } catch (error) {
            console.error('Error adding material:', error);
            setError(t('storagePage.invalidDataFormat'));
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h3>{t('storagePage.addNewMaterial')}</h3>
            {error && <Alert variant="danger">{error}</Alert>}

            {/* Material Name */}
            <Form.Group controlId="materialName">
                <Form.Label>{t('storagePage.materialName')}</Form.Label>
                <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </Form.Group>

            {/* Material Price */}
            <Form.Group controlId="materialPrice">
                <Form.Label>{t('storagePage.materialPrice')}</Form.Label>
                <Form.Control
                    type="number"
                    value={pricePerKg}
                    onChange={(e) => setPricePerKg(e.target.value)}
                    min="0"
                    step="0.01"
                />
            </Form.Group>

            {/* Material Weight */}
            <Form.Group controlId="materialQuantity">
                <Form.Label>{t('storagePage.materialQuantity')}</Form.Label>
                <Form.Control
                    type="number"
                    value={totalQuantityKg}
                    onChange={(e) => setTotalQuantityKg(e.target.value)} // Corrected to ensure editing is possible
                    required
                    min="0"
                    step="0.01"
                />
            </Form.Group>

            {/* Only display IsRaw toggle (always checked and disabled) */}
            <Form.Group controlId="materialIsRaw" className="mt-3">
                <Form.Check
                    type="checkbox"
                    label={t('storagePage.isRaw')}
                    checked={true} // Always checked
                    disabled={true} // Make it unclickable
                />
            </Form.Group>

            <h4>{t('storagePage.compositions')}</h4>
            <Row className="g-3">
                {compositions.map((comp, index) => (
                    <Col xs={12} md={6} lg={4} key={index} className="composition-group">
                        <Form.Group controlId={`compositionElementId-${index}`}>
                            <Form.Label>{t('storagePage.element')}</Form.Label>
                            <Form.Control
                                as="select"
                                value={comp.elementId}
                                onChange={(e) => handleCompositionChange(index, 'elementId', e.target.value)}
                                required
                            >
                                <option value="">{t('storagePage.selectElement')}</option>
                                {elements.map((el) => (
                                    <option key={el.id} value={el.id}>
                                        {el.nameFull} ({el.nameShort})
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId={`compositionQuantity-${index}`} className="mt-2">
                            <Form.Label>{t('storagePage.compositionQuantity')}</Form.Label>
                            <Form.Control
                                type="number"
                                value={comp.quantity}
                                onChange={(e) => handleCompositionChange(index, 'quantity', e.target.value)}
                                required
                                min="0"
                                step="0.01"
                            />
                        </Form.Group>
                        <Form.Group controlId={`compositionTolerance-${index}`} className="mt-2">
                            <Form.Label>{t('storagePage.compositionTolerance')}</Form.Label>
                            <Form.Control
                                type="number"
                                value={comp.tolerance}
                                onChange={(e) => handleCompositionChange(index, 'tolerance', e.target.value)}
                                required
                                min="0"
                                step="0.01"
                            />
                        </Form.Group>
                    </Col>
                ))}
            </Row>

            {/* Check total composition percentage */}
            <div className="mt-3">
                <p>{t('productsPage.compositionTotal')}: {totalCompositionPercentage}%</p>
                {totalCompositionPercentage !== 100 && (
                    <Alert variant="warning">
                        {t('productsPage.compositionWarning')}
                    </Alert>
                )}
            </div>

            <div className="d-flex justify-content-end mt-4">
                <Button variant="secondary" onClick={handleAddComposition} className="me-2">
                    {t('storagePage.addComposition')}
                </Button>
                <Button variant="primary" type="submit">
                    {t('storagePage.addMaterial')}
                </Button>
            </div>
        </Form>
    );
};

export default AddMaterialForm;

// src/api/products.ts
import api from './index';
import { Material } from '../models/Material';

// Fetch all products (materials with isProduct true)
export const getProducts = async (): Promise<Material[]> => {
    try {
        const response = await api.get('/api/warehouse/products');
        return response.data as Material[];
    } catch (error: any) {
        console.error('Error fetching products:', error.response?.data || error.message);
        throw error;
    }
};

// Add a new product (create a new material with isProduct: true)
export const addProduct = async (productData: {
    compositions: { elementId: number; quantity: number; tolerance: number }[];
    isProduct: boolean;
    isRaw: boolean;
    name: string;
    sellingPrice: number;
    weightRequiredKg: number;
}): Promise<Material> => {
    try {
        console.log("Adding product with data:", productData);
        const response = await api.post('/api/warehouse', { ...productData, isProduct: true });
        return response.data as Material;
    } catch (error: any) {
        console.error('Error adding product:', error.response?.data || error.message);
        throw error;
    }
};


// Update a product (update an existing material)
export const updateProduct = async (id: number, product: Partial<Material>): Promise<Material> => {
    try {
        console.log("updateProduct")
        const response = await api.put(`/api/warehouse/${id}`, product);
        return response.data as Material;
    } catch (error: any) {
        console.error('Error updating product:', error.response?.data || error.message);
        throw error;
    }
};

// Delete a product (delete an existing material)
export const deleteProduct = async (productId: number): Promise<void> => {
    try {
        await api.delete(`/api/warehouse/${productId}`);
    } catch (error: any) {
        console.error('Error deleting product:', error.response?.data || error.message);
        throw error;
    }
};

import api from './index';
import { Material } from '../models/Material';

// Fetch all materials from storage
export const getMaterials = async (): Promise<Material[]> => {
    try {
        const response = await api.get('/api/warehouse'); // No headers needed for session-based auth
        return response.data as Material[];
    } catch (error: any) {
        console.error('Error fetching materials:', error.response?.data || error.message);
        throw error;
    }
};

// Add a new material to storage
export const addMaterial = async (materialData: {
    compositions: { elementId: number; quantity: number; tolerance: number }[];
    isProduct: boolean;
    isRaw: boolean;
    name: string;
    pricePerKg: number;
    sellingPrice: number;
    totalQuantityKg: number;
    weightRequiredKg: number;
}): Promise<Material> => {
    try {
        const response = await api.post('/api/warehouse', { ...materialData, isRaw: true }); // Adjust based on your logic
        return response.data as Material;
    } catch (error: any) {
        console.error('Error adding material:', error.response?.data || error.message);
        throw error;
    }
};

// Update a material in storage
export const updateMaterial = async (id: number, material: Partial<Material>): Promise<Material> => {
    try {
        const response = await api.put(`/api/warehouse/${id}`, material); // No headers needed
        return response.data as Material;
    } catch (error: any) {
        console.error('Error updating material:', error.response?.data || error.message);
        throw error;
    }
};

// Delete a material from storage
export const deleteMaterial = async (materialId: number): Promise<void> => {
    try {
        await api.delete(`/api/warehouse/${materialId}`); // No headers needed
    } catch (error: any) {
        console.error('Error deleting material:', error.response?.data || error.message);
        throw error;
    }
};

import React, { useEffect, useState } from 'react';
import { getMaterials, deleteMaterial } from '../api/warehouse';
import AddMaterialForm from '../components/AddMaterialForm';
import EditMaterialForm from '../components/EditMaterialForm';
import { Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Material } from '../models/Material';

const WarehousePage: React.FC = () => {
    const { t } = useTranslation();
    const [materials, setMaterials] = useState<Material[]>([]);
    const [editMaterial, setEditMaterial] = useState<Material | null>(null);
    const [showMaterials, setShowMaterials] = useState<boolean>(false);
    const [showAddForm, setShowAddForm] = useState<boolean>(false);

    const fetchMaterials = async () => {
        try {
            const data = await getMaterials();
            setMaterials(data);
        } catch (err) {
            console.error('Error fetching materials:', err);
        }
    };

    const handleDelete = async (materialId: number) => {
        if (window.confirm(t('storagePage.deleteConfirmation'))) {
            try {
                await deleteMaterial(materialId);
                alert(t('storagePage.deleteSuccess'));
                fetchMaterials();
            } catch (err) {
                console.error('Error deleting material:', err);
                alert(t('storagePage.deleteError'));
            }
        }
    };

    const toggleMaterialsVisibility = () => {
        if (!showMaterials) {
            fetchMaterials();
        }
        setShowMaterials(!showMaterials);
    };

    const toggleAddFormVisibility = () => {
        setShowAddForm(!showAddForm);
    };

    // Format compositions as Sn98Ag2 style
    // Format compositions as Sn98Ag2 style
    const formatComposition = (compositions: any[]) => {
        if (!compositions || compositions.length === 0) {
            console.log('No compositions found:', compositions); // Debugging log
            return t('storagePage.noComposition');
        }

        console.log('Compositions:', compositions); // Debugging log

        // Combine each element symbol and quantity, checking for undefined values
        return compositions
            .map((comp) => {
                console.log('Composition item:', comp); // Debugging log for each item
                // Ensure comp.element and comp.quantity exist
                if (comp.element && comp.quantity) {
                    console.log('Valid element and quantity:', comp.element.nameShort, comp.quantity); // Debug valid entries
                    return `${comp.element.nameShort}${comp.quantity}`;
                }
                console.warn('Invalid composition:', comp); // Warn if composition is invalid
                return ''; // Return an empty string if comp.element is undefined
            })
            .filter(Boolean) // Remove any empty strings resulting from undefined elements
            .join(''); // Join all without separators
    };



    return (
        <div>
            <div className="d-flex align-items-center mb-3">
                <h1 className="me-auto">{t('storagePage.title')}</h1>
                <Button onClick={toggleMaterialsVisibility} className="me-2">
                    {showMaterials ? t('storagePage.hideMaterials') : t('storagePage.fetchMaterials')}
                </Button>
                <Button onClick={toggleAddFormVisibility} variant="success">
                    {showAddForm ? t('common.cancelButton') : t('storagePage.addNewMaterial')}
                </Button>
            </div>

            {showMaterials && (
                <Table striped bordered hover responsive>
                    <thead>
                    <tr>
                        <th>{t('storagePage.name')}</th>
                        <th>{t('storagePage.pricePerKg')}</th>
                        <th>{t('storagePage.quantityKg')}</th>
                        <th>{t('storagePage.composition')}</th>
                        <th>{t('storagePage.actions')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {materials.map((material) => (
                        <tr key={material.id}>
                            <td>{material.name}</td>
                            <td>{material.pricePerKg ?? t('storagePage.noData')}</td>
                            <td>{material.totalQuantityKg ?? t('storagePage.noData')}</td>
                            <td>
                                {material.compositions
                                    ? formatComposition(material.compositions)
                                    : t('storagePage.noComposition')}
                            </td>
                            <td>
                                <Button variant="warning" onClick={() => setEditMaterial(material)}>
                                    {t('storagePage.editButton')}
                                </Button>
                                <Button variant="danger" onClick={() => handleDelete(material.id)} className="ms-2">
                                    {t('storagePage.deleteButton')}
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}

            {showAddForm && <AddMaterialForm />}

            {editMaterial && (
                <EditMaterialForm
                    material={editMaterial}
                    onCancel={() => setEditMaterial(null)}
                    onUpdate={() => {
                        setEditMaterial(null);
                        fetchMaterials();
                    }}
                />
            )}
        </div>
    );
};

export default WarehousePage;

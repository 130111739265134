import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import sk from './locales/sk/translation.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            sk: { translation: sk },
        },
        lng: 'sk',                // Set Slovak as the default language
        fallbackLng: 'sk',         // Fallback to Slovak if no language is specified
        interpolation: {
            escapeValue: false, // React already does escaping
        },
    });

export default i18n;

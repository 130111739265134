import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PermissionTable from '../components/PermissionTable';
import {
    fetchRoles,
    fetchUsers,
    fetchPermissions,
    assignPermissionToRole,
    removePermissionFromRole,
    assignPermissionToUser,
    removePermissionFromUser,
} from '../api/permissions';

interface Permission {
    id: number;
    name: string;
}

interface Role {
    id: number;
    name: string;
    permissions: { id: number; granted: boolean }[];
}

interface User {
    id: number;
    username: string;
    role: string;
    permissions: { id: number; granted: boolean }[];
}

const ManagerPage: React.FC = () => {
    const { t } = useTranslation();
    const [roles, setRoles] = useState<Role[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [permissions, setPermissions] = useState<Permission[]>([]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const [rolesData, usersData, permissionsData] = await Promise.all([
                    fetchRoles(),
                    fetchUsers(),
                    fetchPermissions(),
                ]);

                setRoles(
                    rolesData.map((role: any) => ({
                        ...role,
                        permissions: permissionsData.map((permission: Permission) => ({
                            id: permission.id,
                            granted: role.permissions.some((rp: any) => rp.id === permission.id),
                        })),
                    }))
                );

                setUsers(
                    usersData.map((user: any) => ({
                        ...user,
                        role: user.role ? user.role.name : t("managerPage.noRole"),
                        permissions: permissionsData.map((permission: Permission) => ({
                            id: permission.id,
                            granted: user.permissions.some((up: any) => up.id === permission.id),
                        })),
                    }))
                );

                setPermissions(permissionsData);
            } catch (error) {
                console.error(t("managerPage.errorLoadingData"), error);
            }
        };
        loadData();
    }, [t]);

    const handlePermissionToggle = async (entityId: number, permissionId: number, granted: boolean, isRole: boolean) => {
        try {
            if (granted) {
                if (isRole) {
                    await removePermissionFromRole(entityId, permissionId);
                } else {
                    await removePermissionFromUser(entityId, permissionId);
                }
            } else {
                if (isRole) {
                    await assignPermissionToRole(entityId, permissionId);
                } else {
                    await assignPermissionToUser(entityId, permissionId);
                }
            }

            const [rolesData, usersData] = await Promise.all([fetchRoles(), fetchUsers()]);
            setRoles(
                rolesData.map((role: any) => ({
                    ...role,
                    permissions: permissions.map((permission: Permission) => ({
                        id: permission.id,
                        granted: role.permissions.some((rp: any) => rp.id === permission.id),
                    })),
                }))
            );
            setUsers(
                usersData.map((user: any) => ({
                    ...user,
                    role: user.role ? user.role.name : t("managerPage.noRole"),
                    permissions: permissions.map((permission: Permission) => ({
                        id: permission.id,
                        granted: user.permissions.some((up: any) => up.id === permission.id),
                    })),
                }))
            );
        } catch (error) {
            console.error(t("managerPage.errorUpdatingPermissions"), error);
            alert(t("managerPage.contactAdmin"));
        }
    };

    return (
        <div>
            <h1>{t("managerPage.title")}</h1>

            <h2>{t("managerPage.rolePermissions")}</h2>
            <PermissionTable
                entities={roles}
                permissions={permissions}
                onTogglePermission={(roleId, permissionId, granted) =>
                    handlePermissionToggle(roleId, permissionId, granted, true)
                }
                entityType="Role"
            />

            <h2>{t("managerPage.userPermissions")}</h2>
            <PermissionTable
                entities={users}
                permissions={permissions}
                onTogglePermission={(userId, permissionId, granted) =>
                    handlePermissionToggle(userId, permissionId, granted, false)
                }
                entityType="User"
            />
        </div>
    );
};

export default ManagerPage;
